<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            修改账户密码
        </div>
        <div class="vip-content-inner edit-password">
            <div class="login-register-form db ">
                <el-form label-position="top" ref="accountPassForm" :model="accountPassForm" :rules="accountPassRules">
					<input type="password" class="inputhide" id=""/>
                    <el-form-item label="" prop="account">
                        <div class="con">
                            <el-input disabled v-model="accountPassForm.account" placeholder="邮箱或手机号码"></el-input>
                        </div>
                    </el-form-item>
					<el-form-item label="验证码" prop="code">
						<div class="con">
							<el-input v-model="accountPassForm.code" placeholder="请填写验证码"></el-input>
							<div class="get-code" @click="getCode">{{ getSmsCodeText }}</div>
						</div>
					</el-form-item>
                    <el-form-item label="" prop="newPassword">
                        <div class="con">
                            <el-input type="password" v-model="accountPassForm.oldPassword" placeholder="请输入旧密码" show-password></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="" prop="newPassword1">
                        <div class="con">
                            <el-input type="password" v-model="accountPassForm.newPassword" placeholder="请输入新密码" show-password></el-input>
                        </div>
                        <div class="tip">密码不能低于8位，且包含数字、大写字母、小写字母</div>
                    </el-form-item>
                    <el-form-item label="" prop="oldPassword">
                        <div class="con">
                            <el-input type="password" v-model="accountPassForm.newPassword1" placeholder="请确认密码" show-password></el-input>
                        </div>
                    </el-form-item>
                </el-form>
                <div class="g-btn small tac" @click="updatepassword">
                    <img src="@/assets/img/i-save.png" alt="">
                    保存
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {updatePassword, userDetail} from "@/api/account";
import {emailVerificationCode, smsVerificationCode} from "@/api/login";

export default {
    name: "password",
    data() {
        return {
			accountPassForm: {
				account:'17369888888',
				code:'',
				newPassword:"",
				newPassword1:"",
				oldPassword:"",
            },
			accountPassRules: {
				account: [{required: true, trigger: 'blur', message: '必填项'}],
				code: [{required: true, trigger: 'blur', message: '必填项'}],
				newPassword: [{required: true, trigger: 'blur', message: '必填项'}],
				newPassword1: [{required: true, trigger: 'blur', message: '必填项'}],
				oldPassword: [{required: true, trigger: 'blur', message: '必填项'}],
			},

			disabled:false, // 未获取验证码
			time:0,
			second:60,

        }
    },
	computed: {
		//获取验证码文字
		getSmsCodeText: function() {
			return this.time > 0 ? this.time + 'S后重新获取' : '获取验证码';
		}
	},
	mounted(){
    	if(localStorage.getItem('userInfo')){
			this.accountPassForm.account=JSON.parse(localStorage.getItem('userInfo')).account
		}
		console.log(JSON.parse(localStorage.getItem('userInfo')).account)
		// this.getuserDetail() // 查询用户信息
	},
	methods:{
		isphone(){
			let phone=true
			if(!(/^1[3456789]\d{9}$/.test(this.accountPassForm.account))){
				this.$message({
					type: 'warning',
					message: '请填写正确的手机号为账号！',
					duration: 1500
				});
				phone=false
			}
			return phone
		},


		//获取验证码倒计时
		run: function() {
			this.time = this.second;
			this.timer();
		},
		stop: function(){
			this.time = 0;
			this.disabled = false;
		},
		timer: function() {
			if (this.time > 0) {
				this.time--;
				this.disabled = true
				setTimeout(this.timer, 1000);
			}else{
				this.stop()
			}
		},

		//  -------------------------接口请求
		getuserDetail(){ // 获取用户信息
			console.log(JSON.parse(localStorage.getItem('userInfo')).userId)
			let userId=JSON.parse(localStorage.getItem('userInfo')).userId
			userDetail({userId:userId}).then((res) => {
				if (res.data.code==200) {
					this.accountPassForm.account=res.data.data.phone
				}

			})
		},

		// 确认修改
		updatepassword(){
			this.$refs.accountPassForm.validate(valid => {
				if (valid) {
					var re =/^(?=.*[a-z])(?=.*\d)[^]{8,16}$/;
					var result =  re.test(this.accountPassForm.newPassword);
					if(!result) {
						this.$message({
							type: 'warning',
							message: "密码必须包含数字,字母,且不少于8位！",
							duration: 2000
						});
						return
					}
					if(this.accountPassForm.newPassword != this.accountPassForm.newPassword1){
						this.$message({
							type: 'warning',
							message: "两次密码不一致！",
							duration: 2000
						});
						return
					}
					let param={
						oldPassword:this.accountPassForm.oldPassword,
						newPassword:this.accountPassForm.newPassword,
						newPassword1:this.accountPassForm.newPassword1,
					}
					updatePassword(param).then((res) => {
						if (res.data.code==200) {
							this.$message({
								type: 'success',
								message: "修改成功",
								duration: 2000
							});
						}else{
							this.$message({
								type: 'error',
								message: res.data.message,
								duration: 2000
							});
						}

					})
				}
			})
		},
		//获取验证码
		getCode(){
			if(this.disabled) return
			// if(!this.isphone()) return
            let apiUrl=smsVerificationCode
            let params = {
                phone: this.accountPassForm.account,
            }
            let isphone=/^1[3456789]\d{9}$/.test(this.accountPassForm.account)
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(this.accountPassForm.account)
            if(!isphone && !mailReg){ // 既不是手机号也不是邮箱
                this.$message({
                    showClose: true,
                    message: '请填写正确的账号格式！',
                    type: 'warning'
                });
            }else{
                if(mailReg){ // 邮箱
                    apiUrl=emailVerificationCode
                    params = {
                        email: this.accountPassForm.account,
                    }
                }
                apiUrl(params).then((res) => {
                    if (res.data.code == '200') {
                        this.accountPassForm.code=res.data.data.verificationCode
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.run()
                    } else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                })
            }

		},

	},
}
</script>

<style lang="scss">
/*禁止自动填充表单*/
.inputhide{
	width: 0;
	border: none;
	position: absolute
}
</style>
<style scoped>

</style>
